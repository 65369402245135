<template>
  <v-container class="listParticolariOperatore">
    <v-row>
      <v-col cols="12" sm="3" class="text-typo">
        <v-row :class="!$vuetify.breakpoint.mdAndUp ? 'px-3' : ''">
          <v-col cols="12" class="text-typo pa-0 mt-2">
            <v-btn
              elevation="0"
              :ripple="false"
              @click="$router.go(-1)"
              icon
              fab
              class="font-weight-600 text-capitalize ma-1"
              color="grey"
              ><v-icon x-large>fas fa-arrow-circle-left</v-icon></v-btn
            >
          </v-col>
          <v-col cols="12" class="text-typo pa-0 mt-2">
            <p class="text-h6 mb-0">C. Commessa</p>
            <p
              class="font-weight-600 text-h3 mb-0 copy_cursor"
              @click="copyText(progetto['codice_commessa'])"
            >
              {{ progetto["codice_commessa"] }}
            </p>
          </v-col>
          <v-col cols="12" class="text-typo pa-0 mt-2">
            <span class="text-h6 mb-0">Nome Progetto</span>
            <p
              class="font-weight-600 text-h3 mb-0 copy_cursor"
              @click="copyText(progetto['nome'])"
            >
              {{ progetto["nome"] }}
            </p>
          </v-col>
          <!--v-col cols="6" class="text-typo pa-0 mt-2">
            <p class="text-h6 mb-0">C. Cliente</p>
            <p
              class="font-weight-600 text-h3 mb-0 copy_cursor"
              @click="copyText(progetto['codice_cliente'])"
            >
              {{ progetto["codice_cliente"] }}
            </p>
          </v-col--></v-row
        >
      </v-col>
      <v-col cols="12" sm="9">
        <v-card class="card-shadow">
          <div class="card-border-bottom pa-3">
            <p class="font-weight-600 text-h3 text-typo mb-0">
              Lista Particolari
            </p>
          </div>

          <v-card-text class="px-0 py-0">
            <v-data-table
              no-data-text="Ancora nessun dato"
              :headers="headers"
              :items="particolari"
              :items-per-page="itemsPerPage"
              :page="page"
              :search="search"
              hide-default-footer
              class="table thead-light"
              mobile-breakpoint="0"
              :item-class="
                () => {
                  return 'pointer';
                }
              "
              @click:row="apriParticolare"
            >
              <template v-slot:top>
                <v-toolbar flat height="65">
                  <v-row>
                    <v-col cols="12" md="8" class="pt-0 pb-0">
                      <v-text-field
                        hide-details
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                        "
                        style="max-width: 250px"
                        dense
                        flat
                        filled
                        solo
                        v-model="search"
                        placeholder="Cerca"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >fas fa-search</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template v-slot:item.nome="{ item }">
                <h3 class="ml-2">{{ item.nome }}</h3>
              </template>
            </v-data-table>
            <div class="pa-2 d-flex justify-end">
              <v-pagination
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="primary"
                v-model="page"
                :length="numberOfPages"
                circle
              ></v-pagination>
            </div>
          </v-card-text> </v-card
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiProgetti from "@/js/pages/progetti";
import apiParticolari from "@/js/pages/particolari";

export default {
  name: "listParticolariOperatore",
  data() {
    return {
      particolari: [],
      token: this.$cookies.get("token_goldencar"),
      ruolo: this.$store.state.ruolo,
      id_progetto: this.$route.query.id_progetto,
      search: "",
      page: 1,
      itemsPerPage: 10,
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "nome",
          width: "60%",
        },
        { text: "Part Number", value: "part_number", width: "40%" },
        //{ text: "Note", value: "note", width: "25%" },
      ],
      progetto: {},
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.particolari.length / this.itemsPerPage);
    },
  },
  async created() {
    this.reloadLista();
    this.progetto = await apiProgetti.getOneSimple(this.token, this.id_progetto);
  },
  methods: {
    async reloadLista() {
      let particolari = await apiParticolari.getAllFromProgetto(
        this.token,
        this.id_progetto
      );
      this.particolari = particolari || [];
    },
    apriParticolare(item = {}) {
      this.$router.push({
        path: "/listPezziOperatore",
        query: {
          id_progetto: this.id_progetto,
          id_particolare: item["_id"],
        },
      });
    },
    copyText(value) {
      var textArea = document.createElement("textarea");
      textArea.style.position = "fixed";
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = 0;
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";
      textArea.value = value;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.log("Impossibile copiare nella clipboard", err);
      }

      document.body.removeChild(textArea);
      this.EventBus.$emit("showAlert", ["Testo copiato negli appunti"]);
    },
  },
};
</script>